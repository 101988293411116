import React from "react";
import { useTranslation } from "react-i18next";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
import { Link } from "react-router-dom";

export default function ErrorPage({ message }: { message: string }): JSX.Element {
	const { t } = useTranslation("ErrorPage");

	return (
		<main>
			<div className="errorPage">
				<img src={image.ICONS[e.Icons.ERROR_404]} alt="Error 404" />
				<div className="errorText">
					<div>
						<h2>Error</h2>
						<p>{message}</p>
					</div>
					<Link to="/">
						<button>{t("errorPage.backToHome")}</button>
					</Link>
				</div>
			</div>
		</main>
	);
}
