export enum Icons {
	EXERCISE = "exercise",
	STATISTICS = "statistics",
	SETTINGS = "settings",
	EXAMS = "exams",
	EXAM = "exam",
	STREAK = "streak",
	STREAK_ZERO = "streakZero",
	TIMER = "timer",
	GOLD = "gold",
	SILVER = "silver",
	BRONZE = "bronze",
	KNOWLEDGE = "knowledge",
	DIFFICULTY = "difficulty",
	LEARNING_CURVE = "learningCurve",
	SCORES = "scores",
	CUP = "cup",
	CHECK_CORRECT = "checkCorrect",
	TRACK_PROGRESS = "trackProgress",
	ERROR_404 = "error404",
	APP_STORE = "appStore",
	PLAY_STORE = "playStore",
}

export enum ScreenShots {
	HOME = "home",
	GAMEPLAY = "gameplay",
	STATS = "stats",
	STUDENT_STATS_1 = "studentStats1",
	STUDENT_STATS_2 = "studentStats2",
}

export enum Languages {
	// GRIEK = "el",
	CATALAN = "ca",
	DANISH = "dk",
	DUTCH = "nl",
	ENGLISH_UK = "en-gb",
	ENGLISH_US = "en-us",
	FINNISH = "fi",
	FRENCH = "fr",
	GERMAN = "de",
	HINDI = "hi",
	ITALIAN = "it",
	NORWEGIAN = "no",
	PORTUGUESE = "pt",
	SPANISH = "es",
	SWEDISH = "se",
}

export const supportedLanguages = Object.values(Languages);

export enum Modules {
	INTERVALS = "Intervals",
	SCALES = "Scales",
	MELODIC_DICTATION = "Melodic_dictation",
	CHORDS = "Chords",
	INVERSIONS = "Inversions",
	PROGRESSIONS = "Progressions",
}

export enum Scales {
	MAJOR = "Major (Ionian)",
	DORIAN = "Dorian",
	PHRYGIAN = "Phrygian",
	LYDIAN = "Lydian",
	MIXOLYDIAN = "Mixolydian",
	MINOR = "Minor (Aeolian)",
	LOCRIAN = "Locrian",
	MAJOR_PENTATONIC = "Major pentatonic",
	MINOR_PENTATONIC = "Minor pentatonic",
	BLUES = "Blues",
	MELODIC_MINOR = "Melodic minor",
	HARMONIC_MINOR = "Harmonic minor",
	CHROMATIC = "Chromatic",
}

export enum Chords {
	MAJOR = "Major",
	DOMINANT = "Dominant",
	MAJOR_7TH = "Major 7th",

	MINOR = "Minor",
	MINOR_7TH = "Minor 7th",
	MINOR_MAJOR_7TH = "Min/Maj 7th",

	DIM = "Diminished",
	DIM_7TH = "Dim 7th",
	HALF_DIM_7TH = "Half-dim 7th",

	AUG = "Augmented",
	AUG_7TH = "Aug 7th",
	AUG_MAJOR_7TH = "Aug Major 7th",

	SUS2 = "Sus2",
	SUS4 = "Sus4",
}

export enum Inversions {
	MAJOR = "Major",
	MAJOR_1ST_INVERSION = "Major 1st",
	MAJOR_2ND_INVERSION = "Major 2nd",
	MINOR = "Minor",
	MINOR_1ST_INVERSION = "Minor 1st",
	MINOR_2ND_INVERSION = "Minor 2nd",
	DIM = "Diminished",
	DIM_1ST_INVERSION = "Diminished 1st",
	DIM_2ND_INVERSION = "Diminished 2nd",
	AUG = "Augmented",
	AUG_1ST_INVERSION = "Augmented 1st",
	AUG_2ND_INVERSION = "Augmented 2nd",
}

export enum Degrees {
	I = "I",
	i = "i",
	bII = "bII",
	bii = "bii",
	II = "II",
	ii = "ii",
	bIII = "bIII",
	biii = "biii",
	III = "III",
	iii = "iii",
	IV = "IV",
	iv = "iv",
	bV = "bV",
	bv = "bv",
	V = "V",
	v = "v",
	bVI = "bVI",
	bvi = "bvi",
	VI = "VI",
	vi = "vi",
	bVII = "bVII",
	bvii = "bvii",
	VII = "VII",
	vii = "vii",
}
