import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams, Outlet } from "react-router-dom";
import "./styles/style.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import Pricing from "./pages/Pricing";
import Legal from "./pages/legal/Legal";
import CookiePolicy from "./pages/legal/CookiePolicy";
import TermsOfService from "./pages/legal/TermsOfService";
import PrivacyPolicy from "./pages/legal/PrivacyPolicy";
import Schools from "./pages/Schools";
import Support from "./pages/Support";
import FAQ from "./pages/FAQ";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
import * as e from "./data/enums";
import { Suspense } from "react";

const LanguageHandler = () => {
	const { lang } = useParams();

	if (!lang) return null;
	if (!e.supportedLanguages.includes(lang as e.Languages)) return <ErrorPage message="404" />;

	return <Outlet />;
};

export default function App() {
	return (
		<HelmetProvider>

			<Suspense fallback={<div></div>}>
				<ErrorBoundary>
					<Router>
						<div className="App">
							<div className="left"></div>
							<div className="center">
								<Header />
								<AvailableRoutes />
								<Footer />
							</div>
							<div className="right"></div>
						</div>
					</Router>
				</ErrorBoundary>
			</Suspense>
		</HelmetProvider>
	);
}

function AvailableRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Navigate to="/en-us/" replace />} />

			<Route path="/404" element={<ErrorPage message="404" />} />

			<Route path="/home" element={<Navigate to="/" replace />} />
			<Route path="/schools" element={<Navigate to="/en-us/schools" replace />} />
			<Route path="/faq" element={<Navigate to="/en-us/faq" replace />} />
			<Route path="/pricing" element={<Navigate to="/en-us/pricing" replace />} />
			<Route path="/support" element={<Navigate to="/en-us/support" replace />} />
			<Route path="/contact" element={<Navigate to="/en-us/contact" replace />} />
			<Route path="/legal" element={<Navigate to="/en-us/legal" replace />} />
			<Route path="/cookiepolicy" element={<Navigate to="/en-us/cookiepolicy" replace />} />
			<Route path="/privacypolicy" element={<Navigate to="/en-us/privacypolicy" replace />} />
			<Route path="/termsofservice" element={<Navigate to="/en-us/termsofservice" replace />} />

			<Route path="/:lang" element={<LanguageHandler />}>
				<Route index element={<Home />} />
				<Route path="schools" element={<Schools />} />
				<Route path="faq" element={<FAQ />} />
				<Route path="pricing" element={<Pricing />} />
				<Route path="support" element={<Support />} />
				<Route path="contact" element={<Contact />} />
				<Route path="legal" element={<Legal />} />
				<Route path="cookiepolicy" element={<CookiePolicy />} />
				<Route path="privacypolicy" element={<PrivacyPolicy />} />
				<Route path="termsofservice" element={<TermsOfService />} />
				<Route path="*" element={<ErrorPage message="404" />} />
			</Route>

			<Route path="*" element={<ErrorPage message="404" />} />
		</Routes>
	);
}
