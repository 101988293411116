import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LOGO } from "../data/constants/images";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as e from "../data/enums";
import * as img from "../data/constants/images";

export default function Header(): JSX.Element {
	const isMobile = useDeviceIsMobile();
	const [changeLanguage, setChangeLanguage] = React.useState(false);
	const chooseLanguageRef = useRef<HTMLDivElement>(null);

	// Handling click outside to reset changeLanguage
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (chooseLanguageRef.current && !chooseLanguageRef.current.contains(event.target as Node))
				setChangeLanguage(false);
		};

		document.body.addEventListener("click", handleClickOutside);
		return () => document.body.removeEventListener("click", handleClickOutside);
	}, []);

	return (
		<header>
			<ChooseLanguage
				ref={chooseLanguageRef}
				changeLanguage={changeLanguage}
				setChangeLanguage={setChangeLanguage}
			/>
			{isMobile ? <MenuMobile /> : <MenuWeb />}
		</header>
	);
}

function MenuWeb() {
	const { t } = useTranslation("Header");
	const navigate = useNavigate();

	return (
		<>
			<img id="logo" alt={t("header.logoAlt")} src={LOGO} onClick={() => navigate(`/${i18next.language}/`)} />
			<nav className="navigation">
				<a href={`/${i18next.language}/`}>{t("header.home")}</a>
				<a href={`/${i18next.language}/schools`}>{t("header.forSchools")}</a>
				<a href={`/${i18next.language}/faq`}>{t("header.faqs")}</a>
				<a href={`/${i18next.language}/pricing`}>{t("header.pricing")}</a>
			</nav>
			<nav className="loginSignUp">
				<a href="https://student.auriseartraining.com/login" className="login" rel="nofollow">
					{t("header.login")}
				</a>
				/
				<a href="https://student.auriseartraining.com/signup" className="signup">
					{t("header.signUp")}
				</a>
				/
				<a href="https://teacher.auriseartraining.com" className="signup">
					{t("header.teacher")}
				</a>
			</nav>
		</>
	);
}

function MenuMobile() {
	const { t } = useTranslation("Header");
	const [menuOpen, setMenuOpen] = React.useState(false);
	const navigate = useNavigate();
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<>
			<img id="logo" alt={t("header.logoAlt")} src={LOGO} onClick={() => navigate(`/${i18next.language}/`)} />
			{/* hamburgermenu */}
			<div className="hamburger-menu" onClick={toggleMenu}>
				<div className="line" />
				<div className="line" />
				<div className="line" />
			</div>
			<div className="menu-mobile" style={{ display: menuOpen ? "block" : "none" }}>
				<nav className="navigation-mobile">
					<a onClick={closeMenu} href={`/${i18next.language}/`}>
						{t("header.home")}
					</a>
					<a onClick={closeMenu} href={`/${i18next.language}/schools`}>
						{t("header.forSchools")}
					</a>
					<a onClick={closeMenu} href={`/${i18next.language}/faq`}>
						{t("header.faqs")}
					</a>
					<a onClick={closeMenu} href={`/${i18next.language}/pricing`}>
						{t("header.pricing")}
					</a>
					{/* <a onClick={closeMenu} href="/support"> 
						Support
					</a> */}
					<a
						onClick={closeMenu}
						href="https://student.auriseartraining.com/login"
						className="login"
						rel="nofollow"
					>
						{t("header.login")}
					</a>
					<a onClick={closeMenu} href="https://student.auriseartraining.com/signup" className="signup">
						{t("header.signUp")}
					</a>
					<a onClick={closeMenu} href="https://teacher.auriseartraining.com" className="signup">
						{t("header.teacher")}
					</a>
				</nav>
			</div>
		</>
	);
}

const ChooseLanguage = React.forwardRef<
	HTMLDivElement,
	{
		changeLanguage: boolean;
		setChangeLanguage: React.Dispatch<React.SetStateAction<boolean>>;
	}
>(({ changeLanguage, setChangeLanguage }, ref) => {
	const navigate = useNavigate();
	const currentLanguage = (i18next.language as e.Languages) || e.Languages.ENGLISH_US;
	const [currentLanguageFlag, setCurrentLanguageFlag] = React.useState(img.FLAGS[i18next.language]);
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		const urlLanguage = window.location.pathname.split("/")[1];
		const langToShow = e.supportedLanguages.includes(urlLanguage as e.Languages) ? urlLanguage : i18next.language;
		setCurrentLanguageFlag(img.FLAGS[langToShow]);
		setLoading(false);
	}, []);

	if (loading) return null;

	const handleSelectLanguage = (lang: string) => {
		setChangeLanguage(false);

		if (lang === currentLanguage) return;

		i18next.changeLanguage(lang);
		localStorage.setItem("language", lang);
		setCurrentLanguageFlag(img.FLAGS[lang]);

		const updatedPath = window.location.pathname.replace(`/${currentLanguage}/`, `/${lang}/`);
		navigate(updatedPath, { replace: true });
	};

	return (
		<div className="language" ref={ref}>
			{changeLanguage && (
				<div className="flagPopup">
					{Object.keys(img.FLAGS).map((langKey) => (
						<img
							key={langKey}
							className={currentLanguage === langKey ? "flag flagActive" : "flag"}
							src={img.FLAGS[langKey]}
							onClick={() => handleSelectLanguage(langKey)}
							alt={langKey}
							title={langKey}
						/>
					))}
				</div>
			)}
			<img
				className="flag"
				src={currentLanguageFlag}
				onClick={() => setChangeLanguage(true)}
				alt="Change Language"
			/>
		</div>
	);
});
