import React from "react";
import { useTranslation } from "react-i18next";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
// import * as c from "../data/constants/constants";
import { LOGO } from "../data/constants/images";
import HelmetComponent from "../components/HelmetComponent";

export default function Home(): JSX.Element {
	const { t, i18n } = useTranslation("Home");

	return (
		<main className="home">
			<HelmetComponent language={i18n.language} title={t("pageTitle")} description={t("pageDescription")} />

			<section className={`hero`}>
				<img id="logo" alt="logo Auris Ear Training" src={LOGO} />
				<div className="heroTextAndLogo">
					<h1>Auris Ear Training</h1>
					<p className="long">{t("heroTextLong")}</p>
					<p className="short">{t("heroTextShort")}</p>
				</div>
			</section>

			<div className="button-container">
				<a
					href="https://student.auriseartraining.com/login"
					className={`button button-secondary`}
					rel="nofollow"
				>
					{t("login")}
				</a>
				<a href="https://student.auriseartraining.com" className={`button signUp`}>
					{t("tryWithoutLogin")}
				</a>

				<a
					href="https://play.google.com/store/apps/details?id=com.auris"
					target="_blank"
					rel="noopener noreferrer"
					className="button-stores"
					aria-label={t("downloadPlayStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.PLAY_STORE]} alt="Play Store" />
				</a>
				<a
					href="https://apps.apple.com/app/auris-ear-training/id6476059416"
					target="_blank"
					rel="noopener noreferrer"
					className="button-stores"
					aria-label={t("downloadAppStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.APP_STORE]} alt="App Store" />
				</a>
			</div>

			<section className={`features`}>
				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.HOME]}
						alt="screenshot of student portal home page"
					/>
					<div className="feature-card">
						<h2>{t("features.trainYourEars")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INTERVALS]} alt="Intervals" />
							{t("modules.intervals")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.SCALES]} alt="Scales" />
							{t("modules.scales")}{" "}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.MELODIC_DICTATION]} alt="Melodic Dictation" />
							{t("modules.melodicDictation")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.CHORDS]} alt="Chords" />
							{t("modules.chords")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INVERSIONS]} alt="Inversions" />
							{t("modules.inversions")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.PROGRESSIONS]} alt="Chord Progressions" />
							{t("modules.progressions")}
						</div>
					</div>
				</div>

				<div className="feature">
					<div className="feature-card">
						<h2>{t("features.funExercises")}</h2>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.DIFFICULTY]}
								alt="Suitable for both beginners and advanced musicians"
							/>
							{t("features.beginnersAndAdvanced")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.LEARNING_CURVE]} alt="Gradual difficulty curve" />
							{t("features.gradualDifficulty")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.KNOWLEDGE]} alt="No prior knowledge required" />
							{t("features.noPriorKnowledge")}
						</div>
					</div>
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.GAMEPLAY]}
						alt="screenshot of gameplay"
					/>
				</div>

				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.STATS]}
						alt="screenshot of stats page"
					/>
					<div className="feature-card">
						<h2>{t("features.trackProgress")}</h2>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STREAK]}
								alt="Practice daily to grow your streak and develop a learning habit"
							/>
							{t("features.practiceDaily")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.CUP]}
								alt="Try to beat your high score and stay on top of the leaderboard"
							/>
							{t("features.beatHighScore")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STATISTICS]}
								alt="Keep track of your progress with detailed statistics"
							/>
							{t("features.detailedStats")}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
