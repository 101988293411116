import React from "react";
import { Helmet } from 'react-helmet-async';
import * as e from "../data/enums";
import { useLocation } from "react-router-dom";

export default function HelmetComponent(
	{
		language,
		title,
		description
	}: {
		language: string,
		title: string,
		description: string

	}
): JSX.Element {
	const location = useLocation();
	const canonicalUrl = `https://auriseartraining.com${location.pathname}`;

	return (
		<Helmet>
			<html lang={language} />
			<title>{title}</title>
			<link rel="canonical" href={canonicalUrl} />
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:url" content={canonicalUrl} />
			<meta property="og:type" content="website" />
			<meta http-equiv="Content-Language" content={language} />

			{Object.values(e.Languages).map((lang) => (
				<link
					key={lang}
					rel="alternate"
					hrefLang={lang}
					href={`https://auriseartraining.com/${lang}`}
				/>
			))}
		</Helmet>
	);
}