import React from "react";
import { useTranslation } from "react-i18next";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";
import i18next from "i18next";

export default function Footer(): JSX.Element {
    const { t } = useTranslation("Footer");
    const isMobile = useDeviceIsMobile();
    const currentYear = new Date().getFullYear();
    const currentLanguage = i18next.language;

    return (
        <footer>
            <nav>
                <a href={`/${currentLanguage}/legal`}>{t("footer.legal")}</a>
                <a href={`/${currentLanguage}/contact`}>{t("footer.contact")}</a>
            </nav>
            <div className="rights">
                &copy; {currentYear} Auris Ear Training. {isMobile && <br />}
                {t("footer.patent")}
            </div>
        </footer>
    );
}