// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './i18n/i18n';
import * as e from "./data/enums";
import i18n from 'i18next';

const pathLang = window.location.pathname.split('/')[1];
if (pathLang && e.supportedLanguages.includes(pathLang) && i18n.language !== pathLang) {
  i18n.changeLanguage(pathLang).then(() => {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  });
} else {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

reportWebVitals();