import React from "react";
import { Helmet } from 'react-helmet-async';
import i18next from "i18next";

export default function PrivacyPolicy(): JSX.Element {
	return (
		<>
			<Helmet>
				<title>Privacy Policy for Auris Ear Training</title>
				<meta name="robots" content="noindex, nofollow" />
			</Helmet>
			<main className="legal">
				<section className="card">
					<div className="card-text">
						<h1>Privacy Policy for Auris Ear Training</h1>
						<PrivacyPolicyContent />
					</div>
				</section>
			</main>
		</>
	);
}

export function PrivacyPolicyContent(): JSX.Element {
	const currentLanguage = i18next.language;

	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 26 January 2025
				</p>
			</div>
			<article>
				<h2>Our Privacy Policy</h2>
				<p>
					Our privacy policy applies to information we collect when you use or access our website,
					application, or just interact with us. We may change this privacy policy from time to time. Whenever
					we make changes to this privacy policy, the changes are effective immediately after we post the
					revised privacy policy (as indicated by revising the date at the top of our privacy policy). We
					encourage you to review our privacy policy whenever you access our services to stay informed about
					our information practices and the ways you can help protect your privacy.
				</p>
			</article>
			<article>
				<h2>Information Collection and Use</h2>
				<p>
					We collect information in two ways: information you provide to us directly and information we
					collect automatically through your use of our services. You provide us information directly when you
					participate in interactive features, fill out a form, request customer support, or communicate with
					us in any way. This includes your name, email address, postal address, credit card information, and
					any other information you choose to provide.
				</p>
				<p>
					Automatically collected information includes details of how you use our service, such as access
					times, pages viewed, IP address, and the page you visited before our service. We also gather
					information about the device you use to access our service, like the hardware model, operating
					system, and version, and your device location.
				</p>
				<p>
					We use various technologies to collect this information, which helps us to improve our services,
					understand user behavior, and enhance user experience. These technologies include cookies stored on
					your device and web beacons used in our services and emails. We use this information to see which
					areas and features are popular, personalize the service for you, and to count visits.
				</p>
				<p>
					Additionally, when you sign up for our app, we automatically add your email address to our mailing
					list, which is managed using Brevo. We use this mailing list to communicate with you about updates,
					offers, and information related to ear training that might interest you. This process is part of our
					service's terms, which you agree to upon registration. You can opt-out of these communications at
					any time by using the unsubscribe link in the emails or contacting us directly.
				</p>
			</article>
			<article>
				<h2>Children's Privacy</h2>
				<p>
					While our service is not specifically designed for children, we acknowledge that children might use
					our service. We do not knowingly collect personal information from children under the age of 13
					without obtaining parental consent. If we become aware that we have collected data from a child
					under 13 without parental consent, we will take steps to remove such information. If you believe we
					might have data from or about a child under 13, please contact us.
				</p>
			</article>

			<article>
				<h2>Legal Basis for Processing Your Data</h2>
				<p>
					We process your personal information under several legal bases, including your consent, the
					necessity to fulfill our contractual obligations to you, and our legitimate interest in conducting
					and improving our business, where those interests do not override your fundamental rights and
					freedom related to data privacy.
				</p>
				<p>
					When we rely on consent to process your personal information, we will ensure that such consent is
					clear, informed, and freely given. You have the right to withdraw your consent at any time, which
					may affect the availability and functionality of our services to you.
				</p>
				<p>
					For processing activities necessary for entering into or performing a contract with you, we use
					personal information to fulfill our contractual obligations. For operations that fall under
					legitimate interests, we process your data only when these interests are not outweighed by your data
					protection rights.
				</p>
				<p>
					We regularly review our data processing practices to ensure they align with the best interests of
					our users. Changes to our privacy policy will be made with adequate notice to you, through our
					service, and updates will be effective upon posting. We encourage you to review our policy
					periodically to stay informed of how we are protecting your information.
				</p>
			</article>
			<article>
				<h2>Information We Collect From Other Sources</h2>
				<p>
					In order to provide you with access to the Service, or to provide you with better service in
					general, we may combine information obtained from other sources (for example, a third-party service
					whose application you have authorized or used to sign in) and combine that with information we
					collect through our services.
				</p>
			</article>
			<article>
				<h2>Use of Information</h2>
				<p>We use information about you for various purposes, including to:</p>
				<ul>
					<li>Provide, maintain and improve our services;</li>
					<li>Provide services you request, process transactions and to send you related information;</li>
					<li>
						Send you technical notices, updates, security alerts and support and administrative messages;
					</li>
					<li>Respond to your comments, questions, and requests and provide customer service;</li>
					<li>Communicate with you about news and information related to our service;</li>
					<li>Monitor and analyze trends, usage, and activities in connection with our services; and</li>
					<li>Personalize and improve our services.</li>
				</ul>
				<p>
					By accessing and using our services, you consent to the processing and transfer of your information
					in and to the United States and other countries.
				</p>
			</article>
			<article>
				<h2>Sharing of Information</h2>
				<p>We may share personal information about you as follows:</p>
				<ul>
					<li>
						With third-party vendors and other service providers who need access to your information to
						carry out work on our behalf, such as Google Analytics, Google Firebase, Google Adsense, Stripe;
					</li>
					<li>
						If we believe disclosure is reasonably necessary to comply with any applicable law, regulation,
						legal process, or governmental request;
					</li>
					<li>
						To enforce applicable user agreements or policies, including our{" "}
						<a href={`/${currentLanguage}/termsofservice`}>Terms of Service</a>;
					</li>
					<li>
						In connection with any merger, sale of Auris Ear Training assets, financing, or acquisition of
						all or a portion of our business to another company; and
					</li>
					<li>
						If we notify you through our services (or in our privacy policy) that the information you
						provide will be shared in a particular manner and you provide such information.
					</li>
				</ul>
				<p>We may also share aggregated or anonymized information that does not directly identify you.</p>
			</article>
			<article>
				<h2>Third-Party Analytics and Data Collection</h2>
				<p>
					We use third-party service providers to help us understand and improve the usage of our services.
					These providers include Google Analytics, Google Firebase, and Google Adsense, which use cookies,
					web beacons, and other tracking technologies to collect information about your interactions with our
					services and others' services over time. This information helps us to better tailor our services and
					may be used for advertising and analytics purposes by us and these third parties.
				</p>
				<p>
					Our service providers are carefully chosen for their commitment to user privacy and ensure the
					handling of your data complies with all applicable laws and best practices. We do not control these
					third-party tracking technologies, so we recommend that you read the privacy policies of these third
					parties to understand their practices.
				</p>
				<p>
					If you wish to opt out of such data collection by third parties, you can manage your preferences
					through the settings of your device or browser, including any "Do Not Track" features they may
					provide. For more detailed control, you may visit specific opt-out pages like the Network
					Advertising Initiative or Digital Advertising Alliance.
				</p>
			</article>
			<article>
				<h2>Our Use of Google AdSense</h2>
				<p>
					We may use Google AdSense to display ads on our website. This service provided by Google uses data
					collected from your visits to our website and other sites to provide personalized advertising.
					Google's use of advertising cookies enables it and its partners to serve ads to our users based on
					their visit to our sites and/or other sites on the Internet.
				</p>
			</article>
			<article>
				<h2>Security</h2>
				<p>
					We take reasonable measures to help protect personal information from loss, theft, misuse, and
					unauthorized access, disclosure, alteration, and destruction.
				</p>
			</article>
			<article>
				<h2>Data Retention</h2>
				<p>
					We retain personal information for as long as necessary to fulfill the purposes outlined in this
					privacy policy unless a longer retention period is required or permitted by law. This includes
					retaining your information to provide you with the services you have requested and to comply with
					our legal obligations, resolve disputes, and enforce our policies.
				</p>
				<p>
					When we no longer require the personal information we have collected about you, we will either
					delete it, anonymize it, or isolate it from further use, depending on the specific circumstances and
					in accordance with our internal policies and applicable law.
				</p>
			</article>
			<article>
				<h2>International Data Transfers</h2>
				<p>
					While we primarily store and process your personal data within the European Economic Area (EEA),
					there are times when we need to transfer and process such data outside the EEA, especially in the
					United States. This is particularly the case when we utilize services that have databases or
					infrastructure in the US, such as Google Cloud backup or Stripe for payment processing.
				</p>
				<p>
					We ensure that such transfers are compliant with applicable data protection laws, including the
					GDPR. When we transfer your data to service providers in the US or other countries outside of the
					EEA, we rely on recognized legal frameworks ensuring adequate protection of your data. This includes
					mechanisms like the EU-US Privacy Shield, standard contractual clauses approved by the European
					Commission, or reliance on a service provider's Binding Corporate Rules.
				</p>
				<p>
					Regardless of where your data is processed, we implement suitable measures to ensure that your
					personal data remains protected and secure in accordance with this privacy policy and applicable
					data protection laws.
				</p>
			</article>
			<article>
				<h2>Mailing List and Communications</h2>
				<p>
					By creating an account with Auris Ear Training, you are automatically subscribed to our mailing list
					managed through Brevo. This allows us to send you occasional emails regarding service updates,
					promotional offers, and educational content related to ear training. The subscription to our mailing
					list is part of our effort to enhance your learning experience and keep you informed about our
					latest features and offerings.
				</p>
				<p>
					If you prefer not to receive these emails, you have the right to unsubscribe at any time. Each email
					we send contains an unsubscribe link at the bottom, or you can directly contact us to request
					removal from the mailing list.
				</p>
				<p>
					We respect your privacy and are committed to protecting it through our compliance with this policy
					and relevant data protection laws.
				</p>
			</article>
			<article>
				<h2>Promotional Communications</h2>
				<p>
					As part of our service, you may receive promotional emails from us about new features, special
					offers, or other information related to ear training. These communications are sent through our
					mailing list, which you are automatically subscribed to upon registering for our app. If you do not
					wish to receive such communications, you can opt out at any time by following the unsubscribe link
					included in every email, or by contacting us directly at the provided email address. Opting out of
					promotional emails will not affect your receipt of important service-related communications.
				</p>
			</article>
			<article>
				<h2>Updates and Changes to This Privacy Policy</h2>
				<p>
					We may periodically update our privacy policy to reflect changes in our practices. When we make
					significant changes, we will notify users by prominently displaying a notice on our service.
					However, it is also your responsibility to review this privacy policy regularly. The date at the top
					indicates the last time this policy was updated.
				</p>
			</article>
			<article>
				<h2>Right to Object and Lodge Complaints</h2>
				<p>
					You have the right to object to certain types of data processing activities. Should you wish to
					exercise this right, please contact us. Furthermore, if you believe that our processing of your data
					violates GDPR or any other applicable laws, you have the right to lodge a complaint with a
					supervisory authority in your country.
				</p>
			</article>
			<article>
				<h2>Contact Us</h2>
				<p>
					If you have any questions about this privacy policy, please contact us at:{" "}
					<a href="mailto:info@auriseartraining.com">info@auriseartraining.com</a>.
				</p>
				<p>
					You can also refer to our <a href={`/${currentLanguage}/termsofservice`}>Terms of Service</a> for
					more information about how we manage our terms of service.
				</p>
			</article>
		</div>
	);
}
