import React from "react"; //useState
import { useTranslation } from "react-i18next";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
import HelmetComponent from "../components/HelmetComponent";

export default function Schools(): JSX.Element {
	const { t, i18n } = useTranslation("Schools");

	return (
		<main>
			<HelmetComponent language={i18n.language} title={t("schools.pageTitle")} description={t("schools.pageDescription")} />
			<section className={`features`}>
				<div className={`feature`}>
					<div className="feature-card">
						<h2>{t("schools.trackProgress.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.TRACK_PROGRESS]} alt={t("schools.trackProgress.alt")} />
							{t("schools.trackProgress.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.DIFFICULTY]}
								alt={t("schools.trackProgress.difficulty.alt")}
							/>
							{t("schools.trackProgress.difficulty.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.SETTINGS]} alt={t("schools.trackProgress.settings.alt")} />
							{t("schools.trackProgress.settings.description")}
						</div>
					</div>
				</div>

				<img
					src={image.SCREENSHOTS[e.ScreenShots.STUDENT_STATS_1]}
					alt="Screenshot showing student statistics related to ractice time and progress"
					className={`feature`}
				/>

				<div className={`feature`}>
					<div className="feature-card">
						<h2>{t("schools.setTargets.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.GOLD]} alt={t("schools.setTargets.goals.alt")} />
							{t("schools.setTargets.goals.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.KNOWLEDGE]} alt={t("schools.setTargets.knowledge.alt")} />
							{t("schools.setTargets.knowledge.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.LEARNING_CURVE]} alt={t("schools.setTargets.progress.alt")} />
							{t("schools.setTargets.progress.description")}
						</div>
					</div>
				</div>

				<img
					src={image.SCREENSHOTS[e.ScreenShots.STUDENT_STATS_2]}
					alt="A Screenshot showing student statistics related to progress per module"
					className={`feature`}
				/>

				<div className={`feature`}>
					<div className="feature-card">
						<h2>{t("schools.tailoredTraining.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.EXAM]} alt={t("schools.tailoredTraining.exam.alt")} />
							{t("schools.tailoredTraining.exam.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.CUP]} alt={t("schools.tailoredTraining.auditions.alt")} />
							{t("schools.tailoredTraining.auditions.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STATISTICS]}
								alt={t("schools.tailoredTraining.statistics.alt")}
							/>
							{t("schools.tailoredTraining.statistics.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.CHECK_CORRECT]}
								alt={t("schools.tailoredTraining.results.alt")}
							/>
							{t("schools.tailoredTraining.results.description")}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
