import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import * as e from "../data/enums";

const urlLanguage = window.location.pathname.split("/")[1];
const initialBrowserLanguage = navigator.language.toLowerCase();
const defaultLanguage = e.Languages.ENGLISH_US;

let browserLanguage: string = initialBrowserLanguage;
if (browserLanguage === "en") browserLanguage = e.Languages.ENGLISH_US;

const userLanguage = e.supportedLanguages.includes(urlLanguage as e.Languages)
	? urlLanguage
	: initialBrowserLanguage === "en" || initialBrowserLanguage.startsWith("en-")
		? defaultLanguage
		: e.supportedLanguages.includes(initialBrowserLanguage as e.Languages)
			? initialBrowserLanguage
			: defaultLanguage;

i18n.use(HttpBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: defaultLanguage,
		lng: userLanguage,
		debug: false,
		ns: ["Home", "Contact", "AboutUs", "ErrorPage", "Schools", "FAQ", "Footer", "Header", "Pricing"],
		defaultNS: "Home",
		interpolation: { escapeValue: false },
		backend: { loadPath: "/locales/{{lng}}/{{ns}}.json" },
		load: "currentOnly",
	});

export default i18n;
